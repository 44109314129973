.search-form{
    width: 100%;
    max-width: 680px;
}
.search-form-elem{
    padding: 1.4rem 2.8rem;
    border-radius: 3.8rem;
}
.search-form-elem .form-control{
    font-size: 2rem;
    padding: 0.6rem;
}
.search-form-elem .form-control::placeholder{
    opacity: 0.9;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
    .search-form-elem {
      padding: 1rem 1.5rem; /* Adjust padding for smaller screens */
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  
    .search-form-elem .form-control {
      font-size: 1.5rem; /* Adjust font size for smaller screens */
      flex: 1; /* Allow the input to take up remaining space */
    }
  
    .search-form-elem button {
      padding: 0.1rem; /* Adjust button padding for smaller screens */
    }
    
  }