.about{
    padding: 3rem 0;
}
.about-content{
    gap: 4rem;
}
.about-title{
    margin: 1rem 0;
}
.about-img img{
    max-width: 520px;
}
.about-text p{
    margin: 1.8rem 0;
    opacity: 0.8;
}

@media screen and (min-width: 800px){
    .about-content{
        grid-template-columns: repeat(2, 1fr);
    }
    .about-title{
        margin-top: 0;
    }
    .about-img img{
        margin-left: 0;
    }
}